import styled from 'styled-components';

const Headline01 = styled.h1`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-family: ${ ({ regular }) =>
    regular ? 'regular, Arial'
    : 'extraBold, Arial' };
    font-size: 15vw;
    letter-spacing: -3px;
    line-height: 1.1;
    text-align: ${ ({ center }) =>
    center ? 'center'
    : '' };

    span {
        color: ${ ({ theme }) => theme.purple };
    }

    span1 {
        -webkit-text-fill-color: ${ ({ theme }) => theme.body };
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: ${ ({ theme }) => theme.text };
    }
    

    span2 {
        color: ${ ({ theme }) => theme.primary };
    }

    span3 {
        color: ${ ({ theme }) => theme.textSecondary };
    }

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        font-size: 6vw;
    }
`;

export default Headline01;
