import styled, { useTheme } from 'styled-components';
import React from 'react';

import Button from '../components/Button';
import { demoUrl, signupUrl } from '../constants';

const Buttons = ({ className }) => {
    const theme = useTheme();

    return (
        <Roots className={ className }>
            <Button label="Try for free"
                arrow
                to={ signupUrl }
                blank
                background={ theme.primary }
            />

            <Button label="View sample menu"
                to={ demoUrl }
                blank
                background='transparent'
                color={ theme.text }
                border={ theme.border }
            />
        </Roots>
    );}

export default Buttons;

const Roots = styled.div`
    flex-direction: column-reverse;
    gap: 16px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px; 

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        flex-direction: row;
        gap: 32px;
    }
`;