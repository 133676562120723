import React from 'react';
import styled from 'styled-components';

import About from './About';
import FirstPage from './FirstPage';
import Footer from './Footer';
import Header from '../components/Header';
import Signup from '../components/Signup';
import Prices from './Prices';
import Features from './Features';
import Testimonials from './Testimonials';

const Main = () => {
    return (
            <Root>
                <Header />
                <Signup />

                <Container>
                    <FirstPage />

                    <Features />

                    <Testimonials />

                    <Prices />

                    <About />

                    <Footer />
                </Container>
            </Root>
    );
};

export default Main;

const Root = styled.div`
    position: relative;
`;

const Container = styled.div`
    
`;
